import {  Send } from '@material-ui/icons'
import styled from 'styled-components'
import React from 'react'
import { mobile } from '../responsive'

const Container = styled.div`
     height: 60vh;
     background-color: #fcf5f5;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
`
const Title = styled.h1`
     font-size: 70px;
     margin-bottom: 20px;
     ${mobile({fontSize: "50px"})}
`
const Desc = styled.div`
     font-size: 24px;
     font-weight: 300;
     margin-top: 20px;
     ${mobile({ textAlign: "center"})}
`
const InputContainer = styled.div`
     width: 50%;
     height: 40px;
     background-color: white;
     display: flex;
     justify-content: space-between;
     border: 1px solid lightgray;
     ${mobile({width: "80%"})}
`
const Input = styled.input`
     border:none;
     flex: 8;
     padding-left: 20px; 
     outline: none;                 //dividing available space {Input}
`
const Button = styled.button`
     flex: 1;  
     border:none;
     background-color:teal;
     color: white;                  //dividing available space {Button}
`

const NewsLetter = () => {
    return (
        <Container>
            <Title>NewsLetter</Title>
            <Desc>Get timely updates dfrom your favorite products.</Desc>
            <InputContainer>
              <Input placeholder="Your Email"/>
              <Button>
                 <Send/>
              </Button>
            </InputContainer>
        </Container>
    )
}

export default NewsLetter
