import { Facebook , Twitter,Instagram, Pinterest, Phone, Room, MailOutline} from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'
import {mobile} from "../responsive"

const Container = styled.div`
     display: flex;
     ${mobile({flexDirection:"column"})}
`
const Left = styled.div`
     flex:1;
     display: flex;
     flex-direction: column;
     padding: 20px;
`
const Logo = styled.h1``
const Desc = styled.p`
    margin: 20px 0px; 
`
const SocialContainer = styled.div`
    display: flex;
`
const SocialIcon = styled.div`
    width: 40px;
    height:40px;
    border-radius: 50%;
    color: white;
    background-color: #${props=>props.color};
    display: flex;
    align-items:center;
    justify-content: center;
    margin-right:20px;
`

const Center = styled.div`
    flex:1;
    padding: 20px;
    ${mobile({display: "none"})}
`
const Title = styled.h3`
     margin-bottom: 30px;
`
const List = styled.ul`
     margin:0;
     padding: 0;
     list-style: none;
     display: flex;
     flex-wrap: wrap;
`
const ListItem = styled.li`
     width: 50%;
     margin-bottom: 10px;
`

const Right = styled.div`
    flex:1;
    padding: 20px;
    ${mobile({backgroundColor: "#fff8f8"})}
`
const ContactItem = styled.div`
   margin-bottom: 20px;
   display: flex;
   align-items: center;
`

const Payment = styled.img`
   width: 50%;
`

const Footer = () => {
    return (
        <Container>
          <Left>
              <Logo>Emon.</Logo>
              <Desc>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione cupiditate suscipit sit, odit, 
                  tempore quibusdam autem illum ullam quia nemo consectetur neque fugiat adipisci iusto consequuntur 
                  nesciunt earum reiciendis sapiente!
              </Desc>
              <SocialContainer>
                  <SocialIcon color="3b5999">
                      <Facebook/>
                  </SocialIcon >
                  <SocialIcon color="e4405f">
                      <Instagram/>
                  </SocialIcon >
                  <SocialIcon color="55acee">
                      <Twitter/>
                  </SocialIcon>
                  <SocialIcon color="e60023">
                      <Pinterest/>
                  </SocialIcon>
              </SocialContainer>
          </Left>
          <Center>
              <Title>Useful Links</Title>
              <List>
                  <ListItem>Home</ListItem>
                  <ListItem>Cart</ListItem>
                  <ListItem>Man Fashion</ListItem>
                  <ListItem>Woman Fashion</ListItem>
                  <ListItem>Accessories</ListItem>
                  <ListItem>My Account</ListItem>
                  <ListItem>Order Tracking</ListItem>
                  <ListItem>Wishlist</ListItem>
                  <ListItem>Terms</ListItem>
              </List>
          </Center>
          <Right>
              <Title>Contact</Title>
              <ContactItem>
                 <Room style={{marginRight:"10px"}}/> 898 Kampala, Uganda
              </ContactItem>
              <ContactItem>
                 <Phone style={{marginRight:"10px"}}/> +256705711611
              </ContactItem>
              <ContactItem>
                  <MailOutline style={{marginRight:"10px"}}/> contact@emon.dev
              </ContactItem>
              <Payment src="/images/payment.png"/>
          </Right>
        </Container>
    )
}

export default Footer
